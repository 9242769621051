@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");


:root {
  /* Color Variables */
  --black: #000000;
  --white: #ffffff;
  --light-gray: #f8f9fa;
  --dark-gray: #333333;
  --neutral-gray: #7c868f;
  --accent-color: #000000; /* Blue accent color */
  --accent-hover: #00aaff; /* Hover effect */
  --bs-primary: var(--accent-color); 
}

/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--white);
  color: var(--dark-gray); 
  /* background: linear-gradient(80deg, #aa00ff, #007bff); */
}

::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

body {
  background-color: var(--white);
  color: var(--dark-gray);
}

/* If you want to make sure all Bootstrap components use your primary color */
.btn-primary {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.btn-primary:hover {
  background-color: var(--accent-hover);
  border-color: var(--accent-hover);
}
.btn-outline-primary {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.btn-outline-primary:hover {
  background-color: var(--accent-hover);
  border-color: var(--accent-hover);
}
.btn-success {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.btn-success:hover {
  background-color: var(--accent-hover);
  border-color: var(--accent-hover);
}

.navbar {
  background-color: var(--bs-primary);
}

.navbar-light .navbar-nav .nav-link {
  color: var(--white);
}

.nav-link {
  color: var(--black);
}

.nav-link:focus, .nav-link:hover{
color: var(--white) !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: var(--accent-hover);
}

.alert-primary {
  background-color: var(--bs-primary);
  color: var(--white);
}

.alert-primary .alert-link {
  color: var(--white);
}

/* Additional Bootstrap elements like links, forms, etc. */
a {
  color: var(--bs-primary);
}

a:hover {
  color: var(--accent-hover);
}

.text-primary{
  color: var(--accent-color) !important;
}

.card-title{
  color: var(--accent-color) !important;
}

.text-info{
  color: var(--accent-color) !important;
}

.form-control:focus {
  border-color: var(--bs-primary);
  box-shadow: 0 0 0 0.25rem var(--accent-color) ;
}

/* modal */
.modal-backdrop {
  opacity: 0 !important;
  z-index: 0;
}

/* Header */
header {
  background-color: var(--light-gray) !important;
  color: var(--white) !important;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px var(--black);
}

header .logo {
  font-size: 28px;
  font-weight: bold;
}

nav a {
  color: var(--white) !important;
  text-decoration: none;
  margin: 0 20px;
  position: relative;
  font-size: 18px;
  transition: color 0.3s;
}

nav a:hover {
  color: var(--accent-color);
}

/* Ensure responsive adjustments */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    padding: 10px;
  }

  nav a {
    margin: 10px 0;
  }

  .navbar-collapse {
    text-align: center;
  }
}

/* Hero Section */
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  /* Adjust based on your design needs */
  /* background-image: url('image/banner-img.png'); */
  /* Replace with your image URL */
  background-size: cover;
  background-position: left center;
  color: var(--white);
  padding: 10px 10px 10px 10px;
  position: relative;
  background-color: var(--accent-color);
  background: linear-gradient(80deg, #454242, #4f4848);
  background-size: 100% 100%;
  animation: gradientAnimation 8s ease infinite;
}

/* Hero Content Styling */
.hero-content {
  position: relative;
  z-index: 2;
  margin-left: 3vw;
  padding: 20px;
}

.hero-content h1 {
  font-size: 40px;
  padding-left: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  animation: fadeIn 2s ease-in-out;
}

.hero-content p {
  padding: 10px;
  font-size: 22px;
  margin-bottom: 30px;
  animation: fadeIn 2s ease-in-out 0.5s;
}

.hero-content button {
  padding: 10px 20px;
  font-size: 1.5rem;
  background: var(--black);
  color: white;
  border: none;
  /* Changed from 1px to none for a cleaner look */
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 8px var(--black);
  /* Added shadow for depth */
}

.hero-content button:hover {
  background-color: var(--white);
  color: var(--black);
  transform: scale(1.05);
  /* Slightly enlarges the button on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.hero-content button:active {
  transform: scale(0.98);
}

/* button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
} */

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

/* image-container line styling */
.image-container-line {
  border-right: 2px solid white;
  height: 50vh;
}

/* Image Container Styling */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3vw;
  bottom: 40px;
  /* Adjust spacing from the bottom if needed */
  width: 35vw;
  /* Adjust size as needed */
  height: 70vh;
  /* Adjust size as needed */
  z-index: 2;
  /* Ensure the image is above other content */
}

.image-container img {
  width: 30vw;
  height: 70vh;
  /* object-fit: cover; Ensures the image covers the container */
}

/* Particle Animation */
.particle-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  pointer-events: none;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2) 20%,
    transparent 60%
  );
  animation: particlesAnimation 10s infinite linear;
}

@keyframes particlesAnimation {
  0% {
    transform: translateX(-100%) translateY(-100%);
  }

  100% {
    transform: translateX(100%) translateY(100%);
  }
}

/* Modal */
.modal {
  display: none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: var(--white);
  margin: 10% auto;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  position: relative;
  animation: zoomIn 0.5s ease-in-out;
}

@keyframes zoomIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

/* Features Section */
.features {
  padding: 60px 20px;
  background-color: var(--white);
  text-align: center;
}

.features h2 {
  font-size: 36px;
  margin-bottom: 40px;
  color: #333;
}

.feature-card {
  display: inline-block;
  width: 45%;
  margin: 20px 2.5%;
  padding: 20px;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px var(--black);
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.feature-icon {
  width: 80px;
  height: 80px;
}

.feature-card h3 {
  margin: 20px 0;
}

.video-section {
  padding: 2rem 0;
}

.video-wrapper {
  flex: 1;
  margin-right: 2rem;
  /* Increased margin to separate video from features list */
  overflow: hidden;
}

.video-player {
  width: 100%;
  max-width: 800px;
  /* Smaller video size */
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.video-player:hover {
  transform: scale(1.05);
}

.features-list {
  flex: 2;
  max-width: 600px;
  text-align: left;
}

.features-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  animation: fadeIn 1s ease-out;
}

.features-list ul {
  padding-left: 0;
}

.feature-item {
  font-size: 1.3rem;
  margin: 0.8rem 0;
  padding: 0.8rem;
  border-radius: 8px;
  background: #f0f8ff;
  /* Light background to highlight the items */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, transform 0.3s ease;
}

.feature-item:hover {
  background: #e6f7ff;
  /* Slightly darker background on hover */
  transform: translateY(-5px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.pricing {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.pricing h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
}

.card {
  border: none;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

/* .card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px var(--black);
} */

.card-header {
  background-color: var(--accent-color) !important;
  color: white;
  text-align: center;
  padding: 20px;
  font-size: 1.5rem;
}

.card-body {
  text-align: center;
}

.card-body p {
  font-size: 1.25rem;
  margin: 20px 0;
}

.btn-custom {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.btn-custom:hover {
  background-color: #0056b3;
  color: white;
}

.card h3 {
  margin: 0;
  color: #007bff;
}

.card p {
  color: #333;
}

.card button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.reviews {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.reviews h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
}

.review-card {
  display: flex;
  align-items: center;
  border: 1px solid #dee2e6;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.review-card:hover {
  box-shadow: 0 8px 16px var(--black);
}

.review-card img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
}

.review-content {
  flex: 1;
}

.review-content .name {
  font-weight: bold;
  margin-bottom: 5px;
}

.review-content .position {
  font-size: 0.9rem;
  color: #6c757d;
  /* A light color for the position */
  margin-bottom: 10px;
}

.stars {
  font-size: 1.2rem;
  color: #ffc107;
  /* Star color */
  margin-bottom: 10px;
}

.review-content p {
  margin: 0;
  font-size: 1rem;
  color: #333;
}

.accordion {
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.accordion-item {
  border: none;
}

.accordion-header {
  background-color: #f8f8f8;
  border: none;
  cursor: pointer;
  padding: 15px;
}

.accordion-button {
  font-weight: bold;
  color: var(--black);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-button:not(.collapsed){
  color: var(--white) !important;
  background-color: var(--accent-color) !important;
}

.accordion-button:focus{
  box-shadow: var(--dark-gray);
}

.nav-pills .nav-link.active {
  color: var(--white);
  background-color: var(--black) !important;
}

.accordion-body {
  padding: 20px;
}

.contact-img {
  max-width: 100%;
  height: auto;
}

/* Dashboard */
.dashboard {
  padding: 40px 20px;
  max-width: 1200px;
  margin: auto;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--black);
}

.dashboard h1 {
  font-size: 36px;
  margin-bottom: 30px;
  color: #333;
}

.category-selection {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.category-btn {
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  background-color: #28a745;
  color: var(--white);
  cursor: pointer;
  font-size: 18px;
  margin: 0 10px;
  transition: background-color 0.3s, transform 0.3s;
}

.category-btn:hover {
  background-color: #218838;
  transform: translateY(-3px);
}

/* Post Creation Form */
#headline-generation,
#post-customization,
#post-result {
  margin-bottom: 40px;
}

#headline-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

#headline-list li {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}

#headline-list li:hover {
  background-color: #f1f1f1;
}

/* Form Elements */
.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
  color: #7c868f;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

select:focus {
  border-color: #007bff;
  outline: none;
}

/* Footer */
.footer {
  background-color: #343a40;
  /* Dark background color */
}

.footer p {
  margin: 0;
}

.footer a {
  color: #1583f2;
  /* Light color for links */
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #ffffff;
  /* Color change on hover */
  text-decoration: underline;
  /* Underline on hover */
}

@media screen and (min-width: 992px) and (max-width: 1114px) {
  .image-container {
    width: 30vw;
    height: 70vh;
  }

  .image-container img {
    width: 25vw;
    height: 60vh;
  }

  .hero-content h1 {
    font-size: 35px;
    margin-top: 0;
    animation: fadeIn 2s ease-in-out;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .image-container {
    width: 35vw;
    height: 60vh;
  }

  .image-container img {
    width: 25vw;
    height: 55vh;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1vw;
    bottom: 40px;
    /* Adjust spacing from the bottom if needed */
    width: 35vw;
    /* Adjust size as needed */
    height: 70vh;
    /* Adjust size as needed */
    z-index: 2;
    /* Ensure the image is above other content */
  }

  .hero-content {
    position: relative;
    z-index: 2;
    margin-left: 1vw;
    padding: 10px;
  }

  .hero-content h1 {
    font-size: 30px;
    margin-top: 0;
    animation: fadeIn 2s ease-in-out;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .image-container {
    width: 0vw;
    height: 0vh;
  }

  .image-container img {
    content-visibility: hidden;
  }

  .hero-content {
    position: relative;
    z-index: 2;
    margin-left: 1vw;
    padding: 10px;
  }

  .hero-content h1 {
    font-size: 30px;
    margin-top: 0;
    animation: fadeIn 2s ease-in-out;
  }

  .image-container-line {
    border: none;
  }

  .hero {
    display: flex;
    align-items: center;
    justify-content: left;
  }
}

.tab .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  /* Adjust width for icon size */
  height: 3rem;
  /* Adjust height for icon size */
  text-decoration: none;
  color: var(--black);
  /* Adjust icon color */
  border-radius: 999px;
  /* Pills look */
  transition: background-color 0.3s, color 0.3s;
}

.tab .nav-link:hover {
  background-color: var(--black) !important;
  /* Light background on hover */
}

.tab .active-tab {
  border: 2px solid var(--accent-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tab .nav-item {
  list-style: none;
}

.tab .icon-size {
  font-size: 1.5rem;
}

/* copy from old app */
.icon-label svg {
  stroke: #00ca90;
  fill: #00ca90;
}

.btn-check:checked + .icon-label svg {
  stroke: white;
  fill: white;
}

.-positionIco-top {
  position: relative;
  bottom: 2px;
}

svg {
  margin: 5px;
}

.form-control,
.form-select {
  border-radius: 6px;
  border: 1px solid var(--border, #e3e3e3);
  background: var(--main-white, var(--white));
}

/* .form-control:active,
.form-select:active {
  border: solid 1px #54c78c !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px !important;
} */

.form-control:focus,
.form-select:focus {
  border: solid 1px var(--white) !important;
  box-shadow: var(--accent-color) 0px 0px 0px 3px !important;
}

.form-control-error,
.form-select-error {
  border: solid 1px #e43b25 !important;
  box-shadow: rgba(231, 11, 3, 0.341) 0px 0px 0px 3px !important;
}

.bg-success {
  background-color: var(--accent-color) !important;
}

.bg-secondary {
  background-color: #e3e3e3 !important;
}

.message--error {
  color: red;
}

/*Range Drag bar*/
.drag__bar::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background-color: #70829c71;
  border-radius: 5px;
  height: 8px;
  cursor: grab;
}

.drag__bar::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  width: 60px;
  background: var(--black);
}

span {
  color: inherit;
}

/* .sample-content {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background-color: #f8f9fa;
  padding: 10px;
} */

.sample-content {
  font-family: "Arial", sans-serif; /* Customize font */
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

/* Additional CKEditor-specific styles */
.sample-content p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.sample-content strong {
  font-weight: bold;
}

.sample-content em {
  font-style: italic;
}

.sample-content ul {
  padding-left: 20px;
}

.sample-content li {
  margin-bottom: 0.5rem;
}

.sample-content a {
  color: #007bff;
  text-decoration: none;
}

.sample-content a:hover {
  text-decoration: underline;
}

.plan-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
}

.active-plan {
  border: 2px solid #007bff;
  background-color: #e9f5ff;
}

/* .ribbon {
  position: absolute;
  top: 15px;
  right: -15px;
  width: 120px;
  padding: 5px 10px;
  background-color: #007bff;
  color: var(--white);
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  transform: rotate(45deg);
} */

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: right;
}
.ribbon span {
  font-size: 0.8rem;
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  transform: rotate(45deg);
  width: 125px;
  display: block;
  background: #28a745;
  /* background: linear-gradient(#28a745 0%, #79a70a 100%); */
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 17px;
  right: -29px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #28a745;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #28a745;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #28a745;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #28a745;
}

.expiration-date {
  margin-top: 10px;
}

/* .linkedin-post {
  background-color: var(--white);
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.post-header {
  display: flex;
  align-items: center;
}

/* .profile-pic {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 15px;
} */

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info .name {
  font-weight: bold;
  color: #0073b1;
}

.user-info .headline {
  font-size: 14px;
  color: #606770;
}

.post-content {
  flex-grow: 1;
  margin: 15px 0;
  line-height: 1.5;
  color: #333;
}

.hashtags {
  color: #0073b1;
  font-size: 14px;
  margin-bottom: 10px;
}

.post-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.post-actions button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: #606770;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.post-actions button:hover {
  color: #0073b1;
}

.post-actions button i {
  margin-right: 5px;
}

.post-actions button.like i::before {
  content: "👍";
}

.post-actions button.comment i::before {
  content: "💬";
}

.post-actions button.share i::before {
  content: "🔗";
}

.post-actions button.send i::before {
  content: "✉️";
}

/* Responsive Design */
@media (max-width: 768px) {
  .linkedin-post {
    padding: 15px;
  }
  .profile-pic {
    width: 40px;
    height: 40px;
  }
  .user-info .name {
    font-size: 16px;
  }
  .user-info .headline {
    font-size: 12px;
  }
  .post-content {
    font-size: 14px;
  }
  .post-actions button {
    font-size: 12px;
  }
}

.post-container {
  background-color: var(--white);
  border: 1px solid #ddd;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
/* .profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #0073b1;
  margin-right: 10px;
} */
.profile-details {
  flex-grow: 1;
}
.profile-details h4 {
  margin: 0;
  font-size: 16px;
  color: var(--dark-gray);
}
.profile-details p {
  margin: 0;
  font-size: 12px;
  color: var(--dark-gray);
}
.post-content {
  margin: 15px 0;
}
.post-content h3 {
  font-size: 18px;
  margin: 0 0 10px 0;
  color: #333;
}
.post-content p {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
}
.hashtags {
  color: #0073b1;
  font-size: 14px;
  margin: 10px 0;
}
.call-to-action {
  margin: 15px 0;
  font-weight: bold;
  color: #0073b1;
}
.post-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 14px;
}
.post-footer a {
  color: #0073b1;
  text-decoration: none;
}

.theme-slide {
  height: 560px;
  width: 480px;
}

.linked-in-summary {
  padding: 0 30px;
}
.form-title {
  color: var(--black);
  font-size: 1.6rem;
}
@media (max-width: 768px) {
  .linked-in-summary {
    padding: 0;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .theme-slide {
    height: 480px;
    width: 240px;
  }
  .title-text {
    font-size: 1rem;
  }
}
