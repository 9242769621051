.menu-dropdown {
   background-color: #ffffff;
   /* White background for the dropdown menu */
   border: 1px solid #dee2e6;
   /* Border for the dropdown menu */
   border-radius: 0.25rem;
   /* Rounded corners */
}

.item_menu {
   display: block;
   padding: 0.5em 1em;
   /* Padding for each menu item */
   color: #343a40;
   /* Dark text color */
   text-decoration: none;
   /* Remove underline */
   transition: background-color 0.2s ease;
   /* Smooth background color transition */
}

/* Hover effect */
.item_menu:hover {
   background-color: #000000;
   /* Highlight background on hover */
   color: #ffffff !important;
   /* Change text color on hover */
}